<template>
  <div class="monitor-index">
    <div class="monitor-index-left">
      <div>
        <el-input
          v-model.trim="communityName"
          placeholder="请输入账号名"
          size="mini"
        ></el-input>
        <el-button
          type="primary"
          size="mini"
          style="width: 100%; margin-top: 10px; border-radius: 16px"
          @click="addData"
        >
          新 增
        </el-button>
      </div>
      <div
        class="left-list"
        id="listData-wrap"
        ref="listDataWrap"
        v-loading="leftLoading"
      >
        <ul
          v-infinite-scroll="load"
          :infinite-scroll-disabled="leftScrollDisabled"
          :infinite-scroll-distance="10"
        >
          <li
            v-for="(item, i) in listData"
            :key="i"
            @click="selectitem(item)"
            :class="selectObj.id == item.id ? 'select-obj' : ''"
          >
            <div>
              <div
                title="监控中"
                v-if="item.state == 1"
                class="item-status-1"
              ></div>
              <div
                title="账号错误"
                v-else-if="item.state == -1"
                class="item-status-2"
              ></div>
              <div title="停止监控" v-else class="item-status-3"></div>
              <img
                :src="
                  item.redditUser
                    ? item.redditUser.iconImg
                    : require('@/assets/imgs/p.png')
                "
                alt=""
                :onerror="noPic"
              />
              <span class="item-redditUserName">{{ item.redditUserName }}</span>
            </div>

            <el-popover
              placement="right"
              trigger="hover"
              popper-class="custom-popover"
            >
              <div style="text-align: center; margin: 0">
                <div>
                  <el-button
                    class="el-icon-tickets"
                    size="mini"
                    type="text"
                    @click="selectitem(item)"
                  >
                    详情
                  </el-button>
                </div>
                <div>
                  <el-button
                    type="text"
                    size="mini"
                    class="el-icon-delete"
                    style="color: red"
                    @click="delData(item)"
                  >
                    删除
                  </el-button>
                </div>
              </div>
              <span
                slot="reference"
                style="margin-top: 20px !important"
                class="left-list-info"
              >
                ···
              </span>
            </el-popover>
          </li>
        </ul>
        <noMorePage
          style="margin-top:5px"
          :nomore="leftPagination.noMore"
          v-if="listData.length > 0"
        />

        <no-data v-if="listData.length == 0" />
      </div>
    </div>
    <div class="monitor-index-right">
      <totalAll
        v-if="contentList.length > 0"
        :text="'共检索到数据' + total + '条'"
      />
      <div
        class="right-child"
        v-loading="rightLoading"
        v-infinite-scroll="detail"
        :infinite-scroll-disabled="rightScrollDisabled"
        :infinite-scroll-distance="10"
      >
        <div class="right-item" v-for="(item, i) in contentList" :key="i">
          <div class="item-title">
            <span>所属社区：</span>
            <img
              :src="
                item.redditSubreddit
                  ? item.redditSubreddit.iconImg
                  : require('@/assets/imgs/r.jpg')
              "
              alt=""
              :onerror="shequPic"
            />
            <span style="margin: 0 10px">{{ item.subreddit }}</span>
            <span style="margin: 0 10px">发表者：{{ item.author }}</span>
            <span>
              {{ item.created }}
            </span>
          </div>
          <div class="item-sub" v-html="item.title"></div>
          <div
            class="item-content"
            v-html="item.text"
            @click="getpopText(item)"
          ></div>
          <div class="item-foot">
            <div>
              <img src="@/assets/imgs/i3.png" alt="" />
              <span>{{ item.numComments }}评论</span>
            </div>
            <div style="margin-left: 18px">
              <img src="@/assets/imgs/i2.png" alt="" />
              <span>{{ item.score }}分</span>
            </div>
            <div class="a-box">
              <span>原链接：</span>
              <!-- <a :href="item.url" target="_blank">
                <span>{{ item.url }}</span>
              </a> -->
              <a
                :href="'https://www.reddit.com' + item.permalink"
                target="_blank"
              >
                <span>reddit.com{{ item.permalink }}</span>
              </a>
            </div>
          </div>
        </div>
        <noMorePage :nomore="rightNomore" v-if="contentList.length > 0" />
      </div>
      <no-data v-if="contentList.length == 0" />
    </div>

    <!---// 点击详情 弹窗-->
    <el-dialog title="详情" :visible.sync="centerDialogVisible" width="40%">
      <h3>
        {{ poptitle }}
      </h3>
      <p>{{ popText }}</p>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserMonitorTasks,
  addUserMonitorTask,
  delUser
} from '../../api/monitor'
import { getPostsByQuery } from '../../api/reddit'
export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/p.png') + '"',
      shequPic: 'this.src="' + require('@/assets/imgs/r.jpg') + '"',
      loading: false,
      communityName: '',
      listData: [],
      selectObj: {},
      contentList: [],

      popText: '',
      poptitle: '',
      centerDialogVisible: false,

      // 右侧流加载
      current: 1,
      limit: 30,
      total: 0,
      rightNomore: true,
      rightScrollDisabled: false,
      rightLoading: false,

      // 左侧流加载
      leftScrollDisabled: false,
      leftLoading: false,
      leftPagination: {
        current: 1,
        limit: 50,
        total: 0,
        noMore: true
      }
    }
  },
  created() {},

  methods: {
    load() {
      this.rightScrollDisabled = true
      this.searchData().then(() => {
        if (!this.leftPagination.noMore) {
          if (this.listData.length > 0) {
            this.selectObj = Object.assign({}, this.listData[0])
            this.current = 1
            this.contentList = []
            this.detail()
            this.popAcquisitionTime(this.selectObj)
          }
        }
      })
    },
    getpopText(row) {
      //console.log(row)
      //var newObg = Object.assign({}, row)
      this.popText = row.text
      this.poptitle = row.title
      this.centerDialogVisible = true
    },

    addData() {
      if (this.communityName.trim() == '') {
        this.$message({
          type: 'warning',
          message: '请输入社区名称！'
        })
        return
      }
      addUserMonitorTask(this.communityName.trim()).then(res => {
        this.$message({
          type: 'success',
          message: '新增成功！'
        })
        this.leftPagination.current = 1
        this.listData = []
        this.communityName = ''
        this.searchData()
      })
    },
    searchData() {
      return new Promise((resolve, reject) => {
        let obj = {
          pageIndex: this.leftPagination.current,
          pageSize: this.leftPagination.limit
        }
        this.leftScrollDisabled = true
        getUserMonitorTasks(obj).then(res => {
          if (res.items.length > 0) {
            this.listData = this.listData.concat(res.items)
            this.leftPagination.current++
            this.leftScrollDisabled = false
            if (res.items.length < this.leftPagination.limit) {
              this.leftPagination.noMore = false
            }
          } else {
            this.leftPagination.noMore = false
          }
          this.leftLoading = false
          this.leftPagination.total = res.all_count
          resolve()
        })
      })
    },
    selectitem(row) {
      if (this.rightLoading) {
        this.$message.warning('正在加载上一条数据，请稍后...')
        return
      }
      this.selectObj = Object.assign({}, row)
      this.current = 1
      this.contentList = []
      this.detail()
      this.popAcquisitionTime(row)
    },
    //最新采集时间弹出效果
    popAcquisitionTime(row) {
      const h = this.$createElement
      if (row.state == -1) {
        this.$notify({
          title: '最新采集时间：',
          //message: row.lastMonitorTime,
          message: h(
            'i',
            { style: 'color: red' },
            '账号错误，采集失败'
            //this.selectObj.lastMonitorTime
          )
        })
      }
      if (row.state == 0) {
        this.$notify({
          title: '最新采集时间：',
          //message: row.lastMonitorTime,
          message: h(
            'i',
            { style: 'color: red' },
            '停止监控，采集失败'
            //this.selectObj.lastMonitorTime
          )
        })
      }
      if (row.state == 1) {
        if (row.lastMonitorTime !== undefined) {
          this.$notify({
            title: '最新采集时间：',
            //message: row.lastMonitorTime,
            message: h('i', { style: 'color: red' }, row.lastMonitorTime)
          })
        } else {
          this.$notify({
            title: '最新采集时间：',
            //message: row.lastMonitorTime,
            message: h('i', { style: 'color: red' }, '新增方案，请稍后再试！')
          })
        }
      }
    },
    detail() {
      let obj = {
        pageIndex: this.current,
        pageSize: this.limit,
        author: this.selectObj.redditUserName
      }
      this.rightLoading = true
      this.rightScrollDisabled = true
      getPostsByQuery(obj).then(res => {
        this.total = res.all_count
        this.rightLoading = false
        if (res.items.length > 0) {
          this.rightScrollDisabled = false
          this.current++
          this.contentList = this.contentList.concat(res.items)
          if (res.items.length < this.limit) {
            this.rightNomore = false
          }
        } else {
          this.rightNomore = false
        }
      })
    },
    // 删除
    delData(row) {
      let that = this
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delUser(row.id).then(res => {
            that.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.leftPagination.current = 1
            this.listData = []

            if (that.selectObj.id) {
              if (row.id == that.selectObj.id) {
                that.selectObj = {}
                that.current = 1
                that.contentList = []
                this.searchData()
              }
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss">
.monitor-index {
  width: 100%;
  height: calc(100vh - 79px);
  background: #f0f2f5;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .monitor-index-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    border: 1px solid #e5e5e5;
    .left-list {
      height: calc(100% - 90px);
      overflow: auto;
      margin-top: 20px;
      position: relative;
      ul {
        padding: 0;
        margin: 0;
        li {
          padding: 10px 10px 10px 0;
          margin: 0;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #f0f2f5;
          cursor: pointer;
          img {
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 50%;
            vertical-align: middle;
          }
          .item-status-1 {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: linear-gradient(0deg, #29f19c, #caf8c7);
            border: 0px solid #000000;
            border-radius: 50%;
            margin-right: 5px;
            margin-left: 5px;
          }
          .item-status-2 {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: linear-gradient(0deg, #fd9376, #df2602);
            border: 0px solid #000000;
            border-radius: 50%;
            margin-right: 5px;
            margin-left: 5px;
          }
          .item-status-3 {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: linear-gradient(0deg, #959595, #3b3a3a);
            border: 0px solid #000000;
            border-radius: 50%;
            margin-right: 5px;
            margin-left: 5px;
          }
          .item-redditUserName {
            font-size: 14px;
            font-weight: 300;
            margin-left: 15px;
            display: inline-block;
            max-width: 120px;
            overflow: hidden;
            vertical-align: middle;
          }
          span:nth-child(2) {
            font-weight: 600;
            margin-top: 8px;
          }

          &:hover {
            background: #cb3700;
            color: #ffffff;
          }
        }
        .select-obj {
          background: #cb3700;
          color: #ffffff;
        }
      }
    }
  }
  .monitor-index-right {
    width: calc(100% - 270px);
    height: 100%;
    position: relative;
    overflow: hidden;
    .right-child {
      width: 100%;
      height: calc(100% - 40px);
      overflow: auto;
      margin-top: 10px;
    }

    .right-item {
      width: 100%;
      background: #ffffff;
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #e5e5e5;
      .item-title {
        white-space: nowrap;
        span {
          font-size: 12px;
          font-weight: 300;
          color: #1a1a1b;
          vertical-align: middle;
        }
        img {
          width: 28px;
          height: 28px;
          object-fit: cover;
          vertical-align: middle;
          border-radius: 50%;
        }
      }
      .item-sub {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        margin: 10px 0;
      }
      .item-content {
        font-size: 14px;
        color: #1a1a1b;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        cursor: pointer;
      }
      .item-foot {
        margin-top: 20px;
        display: flex;
        img {
          vertical-align: middle;
        }
        span {
          vertical-align: middle;
          font-size: 12px;
          margin-left: 5px;
          font-weight: 300;
          color: #898989;
        }
      }
      .a-box {
        margin-left: 18px;

        &:hover {
          cursor: pointer;
          color: #0880c5 !important;
          a {
            color: #0880c5 !important;
          }
        }
      }
    }
  }
}
.custom-popover {
  width: 60px !important;
  min-width: 50px !important;
  padding: 5px;
}
.el-dialog__body {
  height: 500px;
  overflow-y: auto;
  padding-top: 10px;
  h3 {
    border-bottom: 1px solid #bbb;
    padding-bottom: 10px;
    text-align: center;
    color: #000;
  }
  p {
    line-height: 28px;
    font-size: 15px;
    text-indent: 30px;
    color: #000;
  }
}
</style>
